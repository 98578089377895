@use '../Chatbot.vars' as *;

#chatbotWidget {
    &.assistant {
        .messageInput:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            position: relative;
            border-top-color: $assistant-message-input-border-top-color;
            padding-top: 11px;
            padding-right: 18px;
            padding-bottom: 11px;
            padding-left: 18px;

            .messageInputText {
                min-height: 36px;
                margin-right: 0;
                border-color: $assistant-message-inputText-border-color;
                background-color: $assistant-message-inputText-background-color;

                .messageInputTextArea {
                    margin-right: 28px;
                    background-color: transparent;
                }
            }

            .messageInputControls {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;

                .messageInputButton {
                    @include squareDimensions(24px);
                    margin-right: 25px;
                    margin-bottom: 18px;
                    background-color: transparent;
                    background-image: linear-gradient(135deg, $assistant-send-buton-normal-from 20%, $assistant-send-buton-normal-to 100%);

                    &:hover {
                        background-color: transparent;
                        background-image: linear-gradient(135deg, $assistant-send-button-hover-from 20%, $assistant-send-button-hover-to 100%);
                    }

                    &:focus, &:focus-within {
                        background-color: transparent;
                        background-image: linear-gradient(135deg, $assistant-send-button-focus-from 20%, $assistant-send-button-focus-to 100%);
                    }

                    &:active {
                        background-color: transparent;
                        background-image: linear-gradient(135deg, $assistant-send-button-pressed-from 20%, $assistant-send-button-pressed-to 100%);
                    }

                    &:disabled {
                        background-color: transparent;
                        background-image: linear-gradient(135deg, $assistant-send-button-disabled-from 20%, $assistant-send-button-disabled-to 100%);
                    }

                    .messageInputButtonImage {
                        @include squareDimensions(12px);
                        background-image: url('../../../../ASSET/assistantSend.svg');
                    }
                }
            }
        }
    }

    .messageInput:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: none;
        border-top-color: $message-input-border-top-color;
        border-top-style: $message-input-border-top-style;
        border-top-width: $message-input-border-top-width;
        width: 100%;
        padding: 11px;

        .messageInputText {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            margin-right: 6px;
            margin-left: 0;
            border-color: $light-border-color;
            border-style: $light-border-style;
            border-width: $light-border-width;
            border-radius: 5px;
            width: 305px;
            min-height: 40px;
            background-color: $message-input-text-background-color;
            padding-top: 9px;
            padding-right: 14px;
            padding-bottom: 9px;
            padding-left: 14px;

            &.warning {
                outline-color: $message-input-text-warning-outline-color;
                outline-style: $message-input-text-warning-outline-style;
                outline-width: $message-input-text-warning-outline-width;
            }

            &.critical {
                outline-color: $message-input-text-critical-outline-color;
                outline-style: $message-input-text-critical-outline-style;
                outline-width: $message-input-text-critical-outline-width;
            }

            &:focus-within {
                border-color: $focused-border-color;
                border-style: $focused-border-style;
                border-width: $focused-border-width;
                padding-top: 8px;
                padding-right: 13px;
                padding-bottom: 8px;
                padding-left: 13px;
            }

            .messageInputTextArea {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                flex-grow: 1;
                font-size: 14px;
                overflow: visible;
                outline: 0;
                border: 0;
                background-color: $message-input-textarea-background-color;
                color: $message-input-textarea-color;
                overflow-wrap: anywhere;
                word-break: break-word;

                &:focus-visible {
                    box-shadow: none;
                }
            }

            .messageInputTextArea[data-placeholder]:empty:before {
                box-sizing: border-box;
                display: block;
                content: attr(data-placeholder);
                color: $message-input-textarea-placeholder-color;
                cursor: text;
            }
        }

        .messageInputControls {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .messageInputButton {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                box-shadow: none;
                border: none;
                border-radius: $button-border-radius;
                @include squareDimensions(40px);
                background-color: $send-button-normal;
                background-image: none;
                cursor: pointer;
                opacity: $send-button-opacity;

                &:hover {
                    background-color: $send-button-hover;
                }

                &:focus, &:focus-within {
                    background-color: $send-button-focus;
                }

                &:active {
                    background-color: $send-button-pressed;
                }

                &:disabled {
                    background-color: $send-button-disabled;
                    opacity: $send-button-disabled-opacity;
                    cursor: not-allowed;
                }

                .messageInputButtonImage {
                    @include squareDimensions(20px);
                    background-image: url('../../assets/sendButton.svg');
                    background-size: cover;
                }
            }
        }
    }

    &.dummy {
        .messageInput:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            .messageInputText {
                .messageInputTextArea {
                    pointer-events: none;
                }
            }

            .messageInputControls {
                .messageInputButton {
                    pointer-events: none;
                }
            }
        }
    }
}
