@use '../Chatbot.vars' as *;

#chatbotWidget {
    &.assistant {
        .messageGroupContainer:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            &:first-child {
                .messageGroup {
                    margin-top: 1.5rem;
                }
            }

            .messageGroup {
                margin-top: 10px;
                margin-right: 6px;
                margin-bottom: 10px;
                margin-left: 0;
                padding-top: 1px;

                .messageGroupAvatar {
                    .messageGroupAvatarIcon {
                        @include squareDimensions(18px);

                        img {
                            @include squareDimensions(16px);
                        }
                    }
                }

                &.messageGroupDirection-outgoing {
                    margin-right: 0;
                    padding-right: 0;

                    .messageGroupAvatar {
                        display: none;
                    }
                }

                .messageGroupContent {
                    .messageGroupContentHeader {
                        display: none;
                    }

                    .messageGroupContentMessages {
                        .typingIndicatorContainer {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .messageGroupContainer:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        display: flex;
        flex-direction: row;
        align-items: end;

        .messageGroup {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            margin: 12px;

            .messageGroupAvatar {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;

                .messageGroupAvatarIcon {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include squareDimensions(32px);
                    pointer-events: none;

                    img {
                        box-sizing: border-box;
                        margin: 0;
                        @include squareDimensions(30px);
                        object-fit: cover;
                    }
                }
            }

            &.messageGroupDirection-incoming {
                margin-right: auto;
                justify-content: flex-start;

                .messageGroupAvatar {
                    justify-content: flex-start;
                    order: 0;
                    margin-right: 8px;
                    margin-left: 0;

                    .messageGroupAvatarIcon {
                        border-color: $message-incoming-avatar-border-color;
                        border-radius: $message-incoming-avatar-border-radius;
                        border-style: $message-incoming-avatar-border-style;
                        border-width: $message-incoming-avatar-border-width;
                        background-color: $message-incoming-avatar-backgorund-color;

                        img {
                            border-radius: $message-incoming-avatar-border-radius;
                        }
                    }
                }
            }

            &.messageGroupDirection-outgoing {
                justify-content: flex-end;
                margin-left: auto;
                padding-right: 6px;

                .messageGroupAvatar {
                    justify-content: flex-start;
                    order: 1;
                    margin-right: 0;
                    margin-left: 8px;

                    .messageGroupAvatarIcon {
                        border-color: $message-outgoing-avatar-border-color;
                        border-radius: $message-outgoing-avatar-border-radius;
                        border-style: $message-outgoing-avatar-border-style;
                        border-width: $message-outgoing-avatar-border-width;
                        background-color: $message-outgoing-avatar-background-color;

                        img {
                            border-radius: $message-outgoing-avatar-border-radius;
                        }
                    }
                }
            }

            .messageGroupContent {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;

                .messageGroupContentHeader {
                    position: static;
                    box-sizing: border-box;
                    display: flex;
                    margin-top: 6px;
                    margin-bottom: 6px;
                    font-size: $conversation-header-font-size;
                    font-weight: $conversation-header-font-weight;
                    color: $conversation-header-color;

                    &.messageGroupContentSender-chatbot {
                        justify-content: flex-start;
                        padding-right: 14px;
                    }

                    &.messageGroupContentSender-user {
                        justify-content: flex-end;
                        padding-left: 14px;
                    }
                }

                .messageGroupContentMessages {
                    box-sizing: border-box;

                    .typingIndicatorContainer {
                        display: none;

                        .typingIndicator {
                            position: static;
                            padding-top: 3px;
                            padding-right: 2px;
                            padding-bottom: 3px;
                            padding-left: 2px;
                        }
                    }
                }
            }
        }
    }

    &.dummy {
        .messageGroupContainer:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            .messageGroup {
                .messageGroupContent {
                    pointer-events: none;
                }
            }
        }
    }
}
