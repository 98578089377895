@use './ChatbotContainer.vars' as *;
@use '../Chatbot.vars' as *;

.chatbotColorSamples {
    display: none;

    &.assistant {
        --a-closeButton-color: $assistant-header-close-button-color;
    }

    .sendButtonColorSample {
        background-color: $send-buton-color;
    }

    .floatingButtonColorSample {
        background-color: $floating-button-color;
    }

    .messageLinkColorSample {
        background-color: $message-link-color;
    }

    .headerCloseButtonColorSample {
        background-color: $header-close-button-color;
    }
}
