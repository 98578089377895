@use '../Chatbot.vars' as *;

#chatbotWidget {
    .chatbotMessage:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        .searchResultsActions {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            padding-top: 6px;

            &.animating.last {
                position: absolute;
                bottom: 11px;
                left: 52px;
                margin-bottom: 1px;
                border-bottom-right-radius: 0.7em;
                border-bottom-left-radius: 0.7em;
                width: 249px;
                height: 34px;
                padding-bottom: 4px;
                padding-top: 6px;
                background-color: $message-incoming-background-color;
            }

            .searchResultsAction {
                box-shadow: none;
                border: 0;
                background: none;
                padding-top: 0;
                padding-right: 2px;
                padding-bottom: 0;
                padding-left: 2px;
                color: $message-link-normal;
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;

                &:hover, &:focus {
                    text-decoration: underline;
                }

                &:focus, &:focus-visible {
                    border-width: 1px;
                    border-style: dashed;
                    border-color: $message-link-normal;
                    outline: none;
                }

                &.visible {
                    display: block;
                }

                &.hidden {
                    display: none;
                }
            }
        }
    }
}
