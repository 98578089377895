@use '../Chatbot.vars' as *;

#chatbotWidget {
    .typingIndicatorContainer:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        position: relative;
        bottom: 0;

        .typingIndicator {
            position: absolute;
            right: 15px;
            bottom: 0;
            left: 0;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: fit-content;
            height: 1.25em;
            padding: 7px 7px;
            font-size: 0.9em;
            line-height: 1.25em;

            &[hidden] {
                display: none;
            }

            @keyframes typingIndicatorAnimation {
                0% {
                    transform: translateY(0);
                }
                28% {
                    transform: translateY(-5px);
                }
                44% {
                    transform: translateY(0);
                }
            }

            .typingIndicatorDot {
                display: inline-block;
                border-radius: 100%;
                width: 4px;
                height: 4px;
                background-color: $chatbot-main-normal;
                animation-duration: 1.5s;
                animation-iteration-count: infinite;
                animation-name: typingIndicatorAnimation;
                animation-timing-function: ease-in-out;

                &:not(:last-child) {
                    margin-right: 3px;
                }

                &:nth-child(2) {
                    animation-delay: 300ms;
                }

                &:nth-child(3) {
                    animation-delay: 400ms;
                }
            }
        }
    }
}
