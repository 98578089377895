@use '../Chatbot.vars' as *;

#chatbotWidget {
    .chatbotMessage:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        .chatbotMessageContentWrapper.chatbotMessageHtmlStreamContent {
            box-sizing: border-box;

            &.hidden {
                display: none;
            }

            .message {
                & > h1:first-child {
                    margin-top: -6px;
                }

                table {
                    width: 366px;
                    margin-top: 4px;
                    margin-bottom: 12px;
                    font-size: 12px;
                    border-collapse: collapse;
                    text-align: center;

                    th, td {
                        border-color: $assistant-message-content-border-color;
                        border-style: solid;
                        border-width: 1px;
                    }
                }

                p {
                    margin-top: 0;
                    margin-right: 0;
                    margin-bottom: 6px;
                    margin-left: 0;
                }

                p + pre {
                    margin-top: 12px;
                }

                code {
                    font-family: monospace;
                }

                pre {
                    display: block;
                    overflow: visible;
                    width: 352px;
                    margin-top: -4px;
                    margin-bottom: 12px;
                    padding-top: 2px;
                    padding-right: 0;
                    padding-bottom: 2px;
                    padding-left: 6px;

                    code {
                        display: block;
                        overflow: visible;
                        font-size: 10px;
                        white-space: pre-wrap;

                        &::before {
                            display: block;
                            position: relative;
                            top: -2px;
                            left: -32px;
                            margin-top: 2px;
                            margin-bottom: 2px;
                            padding-left: 26px;
                            background-color: white;
                            background-size: 16px;
                            background-image: url('../../../../ASSET/images/assistantAvatar.svg');
                            background-repeat: no-repeat;
                            font-family: 'Open Sans', 'Open Sans Extended', Helvetica, Arial, sans-serif;
                            font-size: 14px;
                        }

                        &.language-html {
                            &::before {
                                content: 'HTML';
                            }
                        }

                        &.language-css {
                            &::before {
                                content: 'CSS';
                            }
                        }

                        &.language-javascript {
                            &::before {
                                content: 'JAVASCRIPT';
                            }
                        }
                    }
                }
            }

            .sources {
                &:not(:first-child) {
                    margin-top: 12px;
                }

                .sourcesTitle {
                    font-weight: bold;
                }

                .sourcesList {
                    padding-left: 24px;
                }
            }

            .chatbotMessageContentActions {
                .chatbotMessageContentAction {
                    &:active {
                        background-color: #d6e8ff;
                        background-image: url('../../../../ASSET/images/copysecondary700outline.svg');
                    }

                    &:hover, &:focus {
                        background-color: #e8f2ff;
                    }

                    &:focus {
                        outline: 0;
                        background-color: #d6e8ff;
                        background-image: url('../../../../ASSET/images/copysecondary700outline.svg');
                    }

                    &:focus-visible {
                        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #056dff;
                    }

                    width: 16px;
                    height: 24px;
                    border: 0;
                    border-radius: var(--borderRadiusSizeS, 0.25em);
                    background-color: transparent;
                    background-image: url('../../../../ASSET/images/copyprimary500outline.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }
}
