@use '../Chatbot.vars' as *;

#chatbotWidget {
    &.assistant.streaming {
        .messageListScrollWrapper:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            .messageListScroll {
                .os-scrollbar-vertical {
                    &:focus-within {
                        .os-scrollbar-track {
                            .os-scrollbar-handle {
                                background-color: $assistant-scroll-handle-background-color;
                                border-color: transparent;
                            }
                        }
                    }

                    &:hover, &:focus-within {
                        opacity: 0.6;
                    }

                    &.os-scrollbar-auto-hide-hidden {
                        opacity: 0.6;
                    }

                    .os-scrollbar-track {
                        .os-scrollbar-handle {
                            background-color: $assistant-scroll-handle-background-color;
                            transition: none;

                            &:hover {
                                background-color: $assistant-scroll-handle-background-color;
                            }
                        }
                    }
                }
            }
        }
    }

    &.assistant {
        .messageListScrollWrapper:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            .messageListScroll {
                .messageList {
                    box-sizing: border-box;
                    padding-right: 1.5rem;
                    padding-bottom: 1rem;
                    padding-left: 1.5rem;
                    pointer-events: none;

                    .sources {
                        .sourcesItem a, .chatbotMessageContentAction {
                            pointer-events: all;
                        }
                    }

                    .chatbotMessageContentAction {
                        pointer-events: all;
                    }
                }

                .os-scrollbar-horizontal, .os-scrollbar-vertical {
                    display: none;
                }
            }

            .typingIndicatorContainer {
                display: none;
            }
        }
    }

    .messageListScrollWrapper:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        overflow: hidden;

        .messageListScroll {
            flex: 1 1;
            pointer-events: all;

            [data-overlayscrollbars-padding], [data-overlayscrollbars-viewport]:not([data-overlayscrollbars]) {
                position: static;
            }

            .os-scrollbar-vertical {
                width: 12px;
                background-color: $scroll-background-color;
                opacity: 0.6;
                pointer-events: all;

                .os-scrollbar-track {
                    box-sizing: border-box;
                    margin-left: 1px;
                    margin-right: 1px;
                    width: auto;
                    transition-duration: 0.2s, 0.2s, 0.2s;

                    .os-scrollbar-handle {
                        box-sizing: border-box;
                        background-color: $scroll-handle-background-color;
                    }
                }

                &.os-scrollbar-auto-hide-hidden {
                    visibility: visible;
                    opacity: 0;
                }

                &.os-scrollbar-unusable {
                    visibility: hidden;
                    opacity: 0;
                }

                &:hover, &:focus-within {
                    opacity: 1;
                }

                &:focus-within {
                    .os-scrollbar-track {
                        .os-scrollbar-handle {
                            border-color: $scroll-handle-focused-border-color;
                            border-style: $scroll-handle-focused-border-style;
                            border-width: $scroll-handle-focused-border-width;
                        }
                    }
                }
            }

            .messageList {
                width: 100%;
            }
        }
    }
}
