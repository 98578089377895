@use '../Chatbot.vars' as *;

#chatbotWidget {
    .chatContainer:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        border-bottom: 0;
        height: 0;
    }
}
