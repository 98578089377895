@use '../Chatbot.vars' as *;

#chatbotWidget {
    .userFeedbackContainer:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-self: end;
        margin-bottom: 12px;
        margin-right: 16px;
        border-radius: 5px;
        width: 54px;
        height: auto;

        &.animating.last {
            position: absolute;
            bottom: 0;
            right: 0;
            margin-bottom: 12px;
        }

        .feedbackIconButton {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2px;
            outline: none;
            border-color: $user-feedback-button-border-color;
            border-radius: $user-feedback-button-border-radius;
            border-style: $user-feedback-button-border-style;
            border-width: $user-feedback-button-border-width;
            padding: 1.5px;
            cursor: pointer;

            &:hover {
                border-color: $chatbot-main-hover;
                border-style: solid;
                border-width: 1px;
            }

            &:focus {
                border-color: $user-feedback-button-focus-border-color;
                border-style: $user-feedback-button-border-style;
                border-width: $user-feedback-button-border-width;
                outline-color: $user-feedback-button-focus-outline-color;
                outline-style: $user-feedback-button-focus-outline-style;
                outline-width: $user-feedback-button-focus-outline-width;
            }

            &:disabled {
                cursor: not-allowed;
            }

            .feedbackIcon {
                @include squareDimensions(18px);
                fill: $user-feedback-button-icon-fill-color;
            }
        }
    }
}
