@use '../Chatbot.vars' as *;

#chatbotWidget {
    &.assistant {
        .chatbotMessage:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            .chatbotMessageContentWrapper {
                &:not(.searchResultsContent) {
                    .link, a {
                        color: $assistant-message-link-color;
                    }

                    .link:focus, .link:focus-within, a:focus, a:focus-within {
                        border-color: $assistant-message-link-color;
                    }
                }
            }

            &.chatbotMessageDirection-outgoing {
                padding-right: 8px;

                .chatbotMessageContentWrapper {
                    width: 343px;

                    .chatbotMessageContent {
                        border-color: $assistant-message-outgoing-border-color;
                        background-color: $assistant-message-outgoing-background-color;
                        color: $assistant-message-outgoing-text-color;
                    }
                }
            }

            &.chatbotMessageDirection-incoming {
                overflow: visible;

                .chatbotMessageContentWrapper {
                    width: auto;

                    .chatbotMessageContent {
                        overflow: visible;
                        border-color: transparent;
                        background-color: transparent;
                        padding: 0;
                        color: $assistant-message-text-color;
                        line-height: 1.25;
                    }
                }

                &.chatbotMessageStatus-error {
                    .chatbotMessageContentWrapper {
                        .chatbotMessageContent {
                            color: $assistant-message-error-text-color;
                        }
                    }
                }
            }
        }
    }

    .chatbotMessage:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-bottom: 8px;
        width: 100%;
        background-color: $message-background-color;
        font-size: $message-font-size;

        &:only-child {
            margin-top: 0.2em;
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 0;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-top: 0;
            margin-bottom: 0;
        }

        .chatbotMessageContentWrapper {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            width: 249px;

            &:not(.searchResultsContent) {
                .link, a {
                    color: $message-link-normal;
                    border-color: transparent;
                    border-style: $message-link-border-style;
                    border-width: $message-link-border-width;
                    font-weight: normal;
                }

                .link:link, a:link {
                    text-decoration-line: none;
                }

                .link:focus, .link:focus-within, a:focus, a:focus-within {
                    outline: none;
                    border-color: $message-link-normal;
                    border-style: $message-link-border-style;
                    border-width: $message-link-border-width;
                }

                .link:hover, .link:active, a:hover, a:active {
                    text-decoration-line: none;
                }
            }

            .chatbotMessageContent {
                padding-top: 6px;
                padding-right: 12px;
                padding-bottom: 6px;
                padding-left: 12px;
                font-variant: normal;
                font-weight: normal;
                line-height: 1.5;
                overflow-wrap: anywhere;
                white-space: normal;
                word-break: break-word;
            }
        }

        &.chatbotMessageDirection-outgoing {
            justify-content: flex-end;

            .link:focus, .link:focus-within {
                border-color: $message-outgoing-text-color;
                color: $message-outgoing-text-color;
            }

            .link:hover, .link:active {
                color: $message-outgoing-text-color;
            }

            .chatbotMessageContentWrapper {
                .chatbotMessageContent {
                    border-color: $message-outgoing-border-color;
                    border-style: $message-outgoing-border-style;
                    border-width: $message-outgoing-border-width;
                    border-top-left-radius: 0.7em;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0.7em;
                    border-bottom-left-radius: 0.7em;
                    background-color: $message-outgoing-background-color;
                    color: $message-outgoing-text-color;
                }
            }
        }

        &.chatbotMessageDirection-incoming {
            justify-content: flex-start;
            margin-right: auto;

            .link:focus, .link:focus-within {
                border-color: $message-link-focus;
                color: $message-link-focus;
            }

            .link:hover, .link:active {
                color: $message-link-hover;
            }

            .chatbotMessageContentWrapper {
                .chatbotMessageContent {
                    border-color: $message-incoming-border-color;
                    border-style: $message-incoming-border-style;
                    border-width: $message-incoming-border-width;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0.7em;
                    border-bottom-right-radius: 0.7em;
                    border-bottom-left-radius: 0.7em;
                    background-color: $message-incoming-background-color;
                    color: $message-incoming-text-color;
                }

                &.searchResultsContent {
                    .chatbotMessageContent {
                        border-top-left-radius: 0.7em;
                        padding-top: 6px;
                        padding-right: 12px;
                        padding-bottom: 6px;
                        padding-left: 12px;
                    }
                }
            }
        }

        &.chatbotMessageStatus-error {
            .chatbotMessageContentWrapper {
                .chatbotMessageContent {
                    border-color: $message-error-border-color;
                    border-style: $message-error-border-style;
                    border-width: $message-error-border-width;
                    background-color: $message-error-background-color;
                    color: $message-error-text-color;
                }
            }
        }

        &.chatbotMessageDirection-incoming a.link {
            color: $message-link-normal;
        }

        &.chatbotMessageDirection-outgoing a.link {
            color: $message-outgoing-text-color;
        }
    }

    &.assistant {
        .chatbotMessage:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            &.chatbotMessageDirection-incoming a:hover {
                text-decoration: underline;
            }
        }
    }
}
