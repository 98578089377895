@use '../Chatbot.vars' as *;

#chatbotWidget {
    .chatbotMessage:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        .searchResultsPage {
            overflow: visible;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;

            @keyframes visible {
                0% {
                    display: none;
                    max-height: 0;
                    opacity: 0;
                }

                100% {
                    display: block;
                    max-height: calc(var(--a-searchResult-content-height) * var(--a-searchResults-page-count));
                    opacity: 1;
                }
            }

            @keyframes hidden {
                0% {
                    display: block;
                    max-height: calc(var(--a-searchResult-content-height) * var(--a-searchResults-page-count));
                    opacity: 1;
                }

                100% {
                    display: none;
                    max-height: 0;
                    opacity: 0;
                }
            }

            &.visible.animatable {
                animation-name: visible;
                animation-duration: 0.5s;
                animation-timing-function: ease-in;
                animation-fill-mode: forwards;
            }

            &.hidden.animatable {
                animation-name: hidden;
                animation-duration: 0.5s;
                animation-timing-function: ease-out;
                animation-fill-mode: forwards;
            }

            &:not(.animatable) {
                animation-duration: 0s;
            }

            &.hidden:not(.animatable) {
                max-height: 0;
                display: none;
                animation-duration: 0s;
            }
        }
    }
}
