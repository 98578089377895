@use '../Chatbot.vars' as *;

#chatbotWidget {
    &.assistant {
        .conversationHeader:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            height: 48px;
            border-color: $assistant-header-border-color;

            .conversationHeaderAvatar {
                @include squareDimensions(22px);

                img {
                    @include squareDimensions(20px);
                }
            }

            .conversationHeaderContent {
                .conversationHeaderName {
                    color: $assistant-header-name-color;
                }
            }

            .conversationHeaderCloseButton {
                @include squareDimensions(22px);
                border-radius: 2px;

                .closeIcon {
                    @include squareDimensions(12px);
                }
            }
        }
    }

    .conversationHeader:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-color: $header-border-color;
        border-style: $header-border-style;
        border-top-width: $header-border-top-width;
        border-right-width: $header-border-right-width;
        border-bottom-width: $header-border-bottom-width;
        border-left-width: $header-border-left-width;
        width: 100%;
        height: 69px;
        padding: 12px;
        font-size: $header-font-size;
        user-select: none;

        .conversationHeaderAvatar {
            box-sizing: border-box;
            margin-right: 1em;
            margin-left: 4px;
            border-color: $header-logo-border-color;
            border-radius: $header-avatar-border-radius;
            border-style: $header-avatar-border-style;
            border-width: $header-avatar-border-width;
            @include squareDimensions(44px);

            img {
                border-radius: $header-avatar-border-radius;
                @include squareDimensions(42px);
                object-fit: cover;
            }
        }

        .conversationHeaderContent {
            overflow: hidden;
            flex: 1 1;

            .conversationHeaderName {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: $header-name-font-weight;
                line-height: normal;
                color: $header-name-color;
            }
        }

        .conversationHeaderCloseButton {
            @include focusMixin;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            box-shadow: none;
            border-color: $transparent-border-color;
            border-style: $transparent-border-style;
            border-width: $transparent-border-width;
            border-radius: $header-close-button-border-radius;
            @include squareDimensions(44px);
            background-color: transparent;
            background-image: none;
            cursor: pointer;

            .closeIcon {
                @include squareDimensions(24px);
                overflow: visible;
                flex: 1 1;
            }

            & > svg > path {
                fill: $header-close-button-normal;
                color: $header-close-button-normal;
            }

            &:hover > svg > path {
                fill: $header-close-button-hover;
                color: $header-close-button-hover;
            }

            &:focus > svg > path, &:focus-within > svg > path {
                fill: $header-close-button-focus;
            }

            &:active > svg > path {
                fill: $header-close-button-pressed;
            }

            &:hover {
                outline-color: $header-close-button-hover;
                outline-style: $header-close-button-outline-style;
                outline-width: $header-close-button-outline-width;
            }

            &:hover:active {
                outline: none;
            }
        }
    }

    &.dummy {
        .conversationHeader:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            .conversationHeaderCloseButton {
                pointer-events: none;
            }
        }
    }
}
