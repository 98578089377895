@use '../Chatbot.vars' as *;

#chatbotWidget {
    all: revert-layer;
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 80;
    display: flex;
    flex-direction: column;
    box-shadow: $chatbot-widget-box-shadow;
    background-color: $chatbot-widget-background-color;
    padding: 0;
    font-family: $chatbot-widget-font-family;

    &.assistant {
        box-sizing: border-box;
        font-family: 'Open Sans', 'Open Sans Extended', Helvetica, Arial, sans-serif;
    }

    & *:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        all: revert-layer;
        font-family: inherit;
        box-sizing: content-box;
    }
}

@media only screen and (min-width: 768px) and (min-height: 432px) {
    #chatbotWidget:not(.crmui_Main #chatbotWidget):not(.chatbot_PLUG_assistant_ChatbotWidget #chatbotWidget) {
        position: fixed;
        top: auto;
        left: auto;
        bottom: 24px;
        right: 24px;
        width: 375px;
        height: 70vh;
        max-height: 70vh;
        min-height: min(600px, max(70vh, 100vh - 48px));

        .mainContainer:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            flex: 1 1;
        }

        .hiddenBody:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
            overflow: visible;
        }
    }
}

.crmui_Main, .chatbot_PLUG_assistant_ChatbotWidget {
    #chatbotWidget {
        position: static;
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
    }
}
