$chatbot-main-normal: var(--chatbot-main-normal, var(--a-chatbot-widget-color));
$chatbot-main-hover: var(--chatbot-main-hover, var(--a-chatbot-widget-hover-color));
$chatbot-main-focus: var(--chatbot-main-focus, var(--a-chatbot-widget-focus-color));
$chatbot-main-pressed: var(--chatbot-main-pressed, var(--a-chatbot-widget-pressed-color));
$chatbot-main-disabled: var(--chatbot-main-disabled, var(--a-chatbot-widget-disabled-color));

@mixin focusMixin {
    &:focus {
        border-color: $focused-border-color;
        border-style: $focused-border-style;
        border-width: $focused-border-width;
        outline-color: $focused-outline-color;
        outline-style: $focused-outline-style;
        outline-width: $focused-outline-width;
    }
}
@mixin darkFocusMixin {
    &:focus {
        border-color: $focused-border-color;
        border-style: $focused-border-style;
        border-width: $focused-border-width;
        outline-color: $focused-outline-color;
        outline-style: $focused-outline-style;
        outline-width: $focused-outline-width;
    }
}
@mixin buttonMixin {
    @include focusMixin;
    box-shadow: none;
    background-image: none;
    background-color: $chatbot-main-normal;
    &:hover{
        background-color: $chatbot-main-hover;
    }
    &:focus, &:focus-within{
        background-color: $chatbot-main-focus;
    }
    &:active{
        background-color: $chatbot-main-pressed;
    }
    &:disabled{
        background-color: $chatbot-main-disabled;
    }
}

@mixin squareDimensions($dimension) {
    width: $dimension;
    height: $dimension;
}

// Non customizable variables

$rounded-corner: 50%;
$square-corner: 5px;

$color-primary: #ffffff;
$color-primary-light: #ffffff;
$color-primary-dark: #ffffff;
$color-secondary: #ffffff;
$color-secondary-light: #ffffff;
$color-secondary-dark: #ffffff;

$default-border-color: #f2f2f2;
$light-grey: #ddd;
$lightish-grey: #ccc;
$dark-grey: #666;
$darker-grey: #5e5e5e;

$chatbot-widget-box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.2);
$chatbot-widget-background-color: #ffffff;
$chatbot-widget-font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;

$message-background-color: transparent;
$message-font-size: 14px;
$message-link-border-color: transparent;
$message-link-border-style: dashed;
$message-link-border-width: 1px;
$message-link-font-weight: 700;
$message-incoming-border-style: solid;
$message-incoming-border-width: 1px;
$message-outgoing-border-style: solid;
$message-outgoing-border-width: 1px;
$message-error-border-style: solid;
$message-error-border-width: 1px;
$message-incoming-avatar-border-style: solid;
$message-incoming-avatar-border-width: 1px;
$message-incoming-avatar-backgorund-color: #ffffff;
$message-outgoing-avatar-border-radius: 50%;
$message-outgoing-avatar-border-style: solid;
$message-outgoing-avatar-border-width: 1px;
$message-outgoing-avatar-background-color: #ffffff;

$message-input-border-top-color: #cccccc;
$message-input-border-top-style: solid;
$message-input-border-top-width: 1px;
$message-input-text-background-color: #ffffff;
$message-input-text-warning-outline-color: var(--avaHoney500);
$message-input-text-warning-outline-style: solid;
$message-input-text-warning-outline-width: 2px;
$message-input-text-critical-outline-color: var(--avaCherry500);
$message-input-text-critical-outline-style: solid;
$message-input-text-critical-outline-width: 2px;
$message-input-textarea-background-color: #ffffff;
$message-input-textarea-color: #212121;
$message-input-textarea-placeholder-color: #9e9e9e;

$conversation-header-color: #666666;
$conversation-header-font-size: 14px;
$conversation-header-font-weight: 700;

$send-button-opacity: 1;
$send-button-disabled-opacity: 0.31;

$header-border-color: #cccccc;
$header-border-style: solid;
$header-border-top-width: 0;
$header-border-right-width: 0;
$header-border-bottom-width: 1px;
$header-border-left-width: 0;
$header-font-size: 16px;
$header-avatar-border-radius: 50%;
$header-avatar-border-style: solid;
$header-avatar-border-width: 1px;
$header-name-color: #212121;
$header-name-font-weight: 700;
$header-close-button-border-radius: 4px;
$header-close-button-outline-style: solid;
$header-close-button-outline-width: 1px;

$floating-button-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
$floating-button-border-radius: 50%;
$floating-button-focus-border-color: #000000;
$floating-button-focus-border-style: solid;
$floating-button-focus-border-width: 2px;
$floating-button-focus-outline-color: #ffffff;
$floating-button-focus-outline-style: solid;
$floating-button-focus-outline-width: 2px;

$privacy-policy-border-top-width: 0;
$privacy-policy-border-right-width: 1px;
$privacy-policy-border-bottom-width: 1px;
$privacy-policy-border-left-width: 1px;
$privacy-policy-border-style: solid;
$privacy-policy-border-color: #f2f2f2;
$privacy-policy-padding-top: 0;
$privacy-policy-padding-bottom: 9px;
$privacy-policy-padding-left: 4px;
$privacy-policy-padding-right: 4px;

$user-feedback-button-border-color: #bbbbbb;
$user-feedback-button-border-radius: 5px;
$user-feedback-button-border-style: solid;
$user-feedback-button-border-width: 1px;
$user-feedback-button-focus-border-color: #000000;
$user-feedback-button-focus-outline-color: #000000;
$user-feedback-button-focus-outline-style: solid;
$user-feedback-button-focus-outline-width: 1px;
$user-feedback-button-icon-fill-color: #666666;

// Customizable variables

$button-border-radius: $square-corner; // Options: $rounded-corner or $square-corner

$conversation-header-bg-color: $color-primary-light;
$conversation-header-user-name-bg-color: $conversation-header-bg-color;
$conversation-header-border-top: solid 0px $default-border-color;
$conversation-header-border-right: solid 0px $default-border-color;
$conversation-header-border-bottom: solid 1px $default-border-color;
$conversation-header-border-left: solid 0px $default-border-color;

$button-color: $color-primary-dark;

$send-buton-color: var(--chatbot-send-button-background-color, var(--a-sendButton-background-color, $chatbot-main-normal));
$send-button-normal: $send-buton-color;
$send-button-hover: var(--send-button-hover, var(--a-sendButton-hover-background-color, $chatbot-main-hover));
$send-button-focus: var(--send-button-focus, var(--a-sendButton-focus-background-color, $chatbot-main-focus));
$send-button-pressed: var(--send-button-pressed, var(--a-sendButton-pressed-background-color, $chatbot-main-pressed));
$send-button-disabled: var(--send-button-disabled, var(--a-sendButton-disabled-background-color, $chatbot-main-disabled));

$floating-button-color: var(--chatbot-floating-button-background-color, var(--a-floatingButton-background-color, $chatbot-main-normal));
$floating-button-normal: $floating-button-color;
$floating-button-hover: var(--floating-button-hover, var(--a-floatingButton-hover-background-color, $chatbot-main-hover));
$floating-button-focus: var(--floating-button-focus, var(--a-floatingButton-focus-background-color, $chatbot-main-focus));
$floating-button-pressed: var(--floating-button-pressed, var(--a-floatingButton-pressed-background-color, $chatbot-main-pressed));
$floating-button-disabled: var(--floating-button-disabled, var(--a-floatingButton-disabled-background-color, $chatbot-main-disabled));
$floating-button-bottom: var(--a-floatingButton-bottom, 24px);
$floating-button-right: var(--a-floatingButton-right, 24px);

$main-container-border: solid 1px $default-border-color;

$message-input-content-editor-wrapper-bg-color: $color-primary;
$message-input-content-editor-bg-color: $message-input-content-editor-wrapper-bg-color;
$message-input-content-editor-wrapper-border-radius: 5px;

$transparent-border-color: transparent;
$transparent-border-style: solid;
$transparent-border-width: 2px;
$focused-border-color: black;
$focused-border-style: solid;
$focused-border-width: 2px;
$focused-outline-color: white;
$focused-outline-style: solid;
$focused-outline-width: 2px;
$light-border-color: $lightish-grey;
$light-border-style: solid;
$light-border-width: 1px;
$scroll-background-color: #cccccc;
$scroll-handle-background-color: #666666;
$scroll-handle-focused-border-color: black;
$scroll-handle-focused-border-style: solid;
$scroll-handle-focused-border-width: 1px;

$message-outgoing-background-color: var(--chatbot-message-outgoing-background-color, var(--a-messageGroup-outgoingMessage-background-color, #666666));
$message-outgoing-border-color: var(--chatbot-message-outgoing-border-color, var(--a-messageGroup-outgoingMessage-border-color, $message-outgoing-background-color));
$message-outgoing-text-color: var(--chatbot-message-outgoing-text-color, var(--a-messageGroup-outgoingMessage-text-color, #f2f2f2));
$message-outgoing-avatar-border-color: var(--chatbot-message-outgoing-avatar-border-color, var(--a-messageGroup-outgoingMessageAvatar-border-color, #ffffff));
$message-incoming-background-color: var(--chatbot-message-incoming-background-color, var(--a-messageGroup-incomingMessage-background-color, #f2f2f2)); // This looks too bright but follows the spec
$message-incoming-border-color: var(--chatbot-message-incoming-border-color, var(--a-messageGroup-incomingMessage-border-color, $message-incoming-background-color));
$message-incoming-text-color: var(--chatbot-message-incoming-text-color, var(--a-messageGroup-incomingMessage-text-color, #212121));
$message-error-border-color: var(--chatbot-message-error-border-color, var(--a-messageGroup-errorMessage-border-color, #da3535)); // TODO: Use avaCherry?
$message-error-background-color: var(--chatbot-message-error-background-color, var(--a-messageGroup-errorMessage-background-color, #fceeee));  // TODO: Use avaCherry?
$message-error-text-color: var(--chatbot-message-error-text-color, var(--a-messageGroup-errorMessage-text-color, #212121));
$message-incoming-avatar-border-color: var(--chatbot-message-incoming-avatar-border-color, var(--a-messageGroup-incomingMessageAvatar-border-color, #ffffff));
$message-incoming-avatar-border-radius: var(--chatbot-message-incoming-avatar-border-radius, var(--a-messageGroup-incomingMessageAvatar-border-radius, 50%));

$message-link-color: var(--chatbot-message-link-color, var(--a-messageGroup-link-color, $chatbot-main-normal));
$message-link-normal: $message-link-color;
$message-link-focus: var(--message-link-focus, var(--a-messageGroup-link-focus-color, $chatbot-main-focus));
$message-link-hover: var(--message-link-hover, var(--a-messageGroup-link-hover-color, $chatbot-main-hover));

$header-logo-border-color: var(--chatbot-header-logo-border-color, var(--a-headerLogo-border-color, #ffffff));
$header-close-button-color: var(--chatbot-header-close-button-color, var(--a-closeButton-color, $chatbot-main-normal));
$header-close-button-normal: $header-close-button-color;
$header-close-button-hover: var(--header-close-button-hover, var(--a-closeButton-hover-color, $chatbot-main-hover));
$header-close-button-focus: var(--header-close-button-focus, var(--a-closeButton-focus-color, $chatbot-main-focus));
$header-close-button-pressed: var(--header-close-button-pressed, var(--a-closeButton-pressed-color, $chatbot-main-pressed));

$privacy-policy-message-text-color: var(--chatbot-privacy-policy-message-text-color, var(--a-privacyPolicy-message-color, #00000061));
$privacy-policy-link-text-color: var(--chatbot-privacy-policy-link-text-color, var(--a-privacyPolicy-link-color, #00000061));
$privacy-policy-link-text-decoration: var(--chatbot-privacy-policy-link-text-decoration, var(--a-privacyPolicy-link-text-decoration, underline));
$privacy-policy-link-hover-text-color: var(--chatbot-privacy-policy-link-hover-text-color, var(--a-privacyPolicy-link-hover-color, #00000099));
$privacy-policy-link-hover-text-decoration: var(--chatbot-privacy-policy-link-hover-text-decoration, var(--a-privacyPolicy-link-hover-text-decoration, underline));

$assistant-header-border-color: var(--a-assistant-header-border-color, #e6f0ff);
$assistant-header-name-color: var(--a-assistant-header-name-color, #001c43);
$assistant-header-close-button-color: var(--a-assistant-closeButton-color, #001c43);
$assistant-send-buton-normal-from: var(--a-assistant-sendButton-background-color-from, hsl(266deg 66% 60%));
$assistant-send-buton-normal-to: var(--a-assistant-sendButton-background-color-to, hsl(228deg 76% 61%));
$assistant-send-button-hover-from: var(--a-assistant-sendButton-hover-background-color-from, hsl(266deg 66% 66%));
$assistant-send-button-hover-to: var(--a-assistant-sendButton-hover-background-color-to, hsl(228deg 76% 67%));
$assistant-send-button-focus-from: var(--a-assistant-sendButton-focus-background-color-from, hsl(266deg 66% 66%));
$assistant-send-button-focus-to: var(--a-assistant-sendButton-focus-background-color-to, hsl(228deg 76% 67%));
$assistant-send-button-pressed-from: var(--a-assistant-sendButton-pressed-background-color-from, hsl(266deg 66% 50%));
$assistant-send-button-pressed-to: var(--a-assistant-sendButton-pressed-background-color-to, hsl(266deg 66% 50%));
$assistant-send-button-disabled-from: var(--a-assistant-sendButton-disabled-background-color-from, hsl(266deg 0% 35%));
$assistant-send-button-disabled-to: var(--a-assistant-sendButton-disabled-background-color-to, hsl(228deg 0% 36%));
$assistant-message-input-border-top-color: var(--a-assistant-message-input-border-top-color, #e6f0ff);
$assistant-message-inputText-border-color: var(--a-assistant-message-inputText-border-color, #f4f4f4);
$assistant-message-inputText-background-color: var(--a-assistant-message-inputText-background-color, #f4f4f4);
$assistant-message-text-color: var(--a-assistant-messageGroup-text-color, #001c43);
$assistant-message-error-text-color: var(--a-assistant-messageGroup-errorMessage-text-color, #da3535);
$assistant-message-content-border-color: var(--a-assistant-messageGroup-content-border-color, #f5f0fa);
$assistant-message-outgoing-background-color: var(--a-assistant-messageGroup-outgoingMessage-background-color, #f5f0fa);
$assistant-message-outgoing-border-color: var(--a-assistant-messageGroup-outgoingMessage-border-color, #f5f0fa);
$assistant-message-outgoing-text-color: var(--a-assistant-messageGroup-outgoingMessage-text-color, #001c43);
$assistant-message-link-color: var(--a-assistant-messageGroup-link-color, #0064f0);
$assistant-scroll-handle-background-color: var(--a-assistant-messageList-scroll-handle-background-color, #c7c7c7);

$chatbot-apply-button-color: $send-buton-color;
$chatbot-apply-button-hover-color: $send-button-hover;
$chatbot-apply-button-focus-color: $send-button-focus;
$chatbot-apply-button-pressed-color: $send-button-pressed;
$chatbot-apply-button-disabled-color: $send-button-disabled;
$chatbot-apply-button-text-color: #fff;