@use '../Chatbot.vars' as *;

#chatbotFloatingButton {
    all: revert-layer;
    box-sizing: border-box;
    position: fixed;
    bottom: $floating-button-bottom;
    right: $floating-button-right;
    z-index: 80;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $floating-button-box-shadow;
    outline: none;
    border: none;
    border-radius: $floating-button-border-radius;
    @include squareDimensions(70px);
    background-color: $floating-button-normal;
    background-image: none;
    padding: 0;
    font-family: inherit;
    object-fit: contain;
    cursor: pointer;

    & * {
        all: revert-layer;
        font-family: inherit;
    }

    &:hover {
        box-shadow: $floating-button-box-shadow;
        background-color: $floating-button-hover;
    }

    &:focus {
        $floating-button-focus-border-color: #000000;
        $floating-button-focus-border-style: solid;
        $floating-button-focus-border-width: 2px;
        $floating-button-focus-outline-color: #ffffff;
        $floating-button-focus-outline-style: solid;
        $floating-button-focus-outline-width: 2px;
    }

    &:focus,
    &:focus-within {
        background-color: $floating-button-focus;
    }

    &:active {
        background-color: $floating-button-pressed;
        background-image: none;
    }

    &:disabled {
        background-color: $floating-button-disabled;
    }

    &:hover:active {
        outline: none;
    }

    &.dummy {
        pointer-events: none;
    }
}
