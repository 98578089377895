@use '../Chatbot.vars' as *;

#chatbotWidget {
    --apply-button-height-with-padding: 3rem;
    --a-searchResult-height: 54px;
    --a-searchResult-margin-top: 6px;
    --a-searchResult-margin-bottom: 6px;
    --a-searchResult-separator-height: 1px;
    --a-searchResult-content-height: calc(var(--a-searchResult-height) + var(--a-searchResult-margin-top) + var(--a-searchResult-margin-bottom) + var(--a-searchResult-separator-height) + var(--apply-button-height-with-padding));

    .chatbotMessage:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        .searchResultContainer {
            display: flex;
            flex-direction: column;
        }

        .searchResult {
            overflow: visible;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;
            margin-top: var(--a-searchResult-margin-top);
            margin-bottom: 0.5rem;
            height: var(--a-searchResult-height);
            padding-top: 4px;
            padding-right: 8px;
            padding-bottom: 4px;
            padding-left: 8px;
            border-radius: 8px;
            text-decoration: none;

            &:hover, &:focus {
                background-color: $message-link-hover;
            }

            &:focus {
                outline-color: #ffffff;
                outline-width: 2px;
                outline-style: solid;
                border-color: #000000;
                border-width: 2px;
                border-style: solid;
                padding-top: 2px;
                padding-right: 6px;
                padding-bottom: 2px;
                padding-left: 6px;
            }

            .searchResultTitle {
                position: relative;
                top: 2px;
                overflow: hidden;
                color: $message-link-normal;
                font-weight: bold;
                font-size: 14px;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1.5;
            }

            .searchResultDetails {
                position: relative;
                top: -1px;
                overflow: hidden;
                color: #333333;
                font-size: 14px;
                text-overflow: ellipsis;
                white-space: pre;
                line-height: 1.5;
            }
        }

        .searchResultSeparator {
            margin-right: 2px;
            margin-left: 2px;
            height: var(--a-searchResult-separator-height);
            background-color: #d9d9d9;

            &.lastVisible {
                display: none;
            }
        }

        .applyButton {
            background-color: $chatbot-apply-button-color;
            color: $chatbot-apply-button-text-color;
            border: none;
            cursor: pointer;
            border-radius: 0.2rem;
            font-weight: 700;
            height: 2rem;
            padding: 0;
            margin-bottom: 0.5rem;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 2px #000, 0 0 0 4px #fff;
                background-color: $chatbot-apply-button-focus-color;
            }

            &:hover {
                background-color: $chatbot-apply-button-hover-color;
            }

            &:active {
                background-color: $chatbot-apply-button-pressed-color;
            }

            &:disabled {
                background-color: $chatbot-apply-button-disabled-color;
                cursor: not-allowed;
            }
        }
    }
}
