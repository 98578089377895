@use '../Chatbot.vars' as *;

#chatbotWidget {
    .privacyPolicy:not([class*="os-"]):not([data-overlayscrollbars="host"]):not([data-overlayscrollbars-contents]) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        border: 1px solid #F2F2F2;
        border-color: $privacy-policy-border-color;
        border-style: $privacy-policy-border-style;
        border-top-width: $privacy-policy-border-top-width;
        border-right-width: $privacy-policy-border-right-width;
        border-bottom-width: $privacy-policy-border-bottom-width;
        border-left-width: $privacy-policy-border-left-width;
        padding-top: $privacy-policy-padding-top;
        padding-right: $privacy-policy-padding-right;
        padding-bottom: $privacy-policy-padding-bottom;
        padding-left: $privacy-policy-padding-left;

        .privacyPolicyMessage {
            text-align: center;
            color: $privacy-policy-message-text-color;
            white-space: pre-wrap;
            font-size: 12px;
        }

        .privacyPolicyMessageLink {
            color: $privacy-policy-link-text-color;
            text-decoration: $privacy-policy-link-text-decoration;

            &:link, &:visited {
                color: $privacy-policy-link-text-color;
                text-decoration: $privacy-policy-link-text-decoration;
            }

            &:hover {
                color: $privacy-policy-link-hover-text-color;
                text-decoration: $privacy-policy-link-hover-text-decoration;
            }
        }
    }
}
